var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h4',[_vm._v("SALIDAS DE ALMACEN")]),_c('app-table-registers',{ref:"tr",attrs:{"getList":_vm.getList},on:{"btnNew":function($event){_vm.$refs.dFormCreat.show();
          _vm.$refs.formCre.reset();}}},[_c('template',{slot:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("FECHA")]),_c('th',[_vm._v("ALMACEN")]),_c('th',[_vm._v("CANTIDAD")]),_c('th')])]),_c('tbody',[_c('app-tr-loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"colspan":"5"}}),_vm._l((_vm.list),function(l){return _c('tr',{key:l.id},[_c('td',[_vm._v(_vm._s(l.id))]),_c('td',[_vm._v(_vm._s(l.created_at))]),_c('td',[_vm._v(_vm._s(l.store_name))]),_c('td',[_vm._v(_vm._s(l.total_quantity))]),_c('td',[_c('div',{staticClass:"btn-group"},[_c('router-link',{staticClass:"btn btn-light btn-sm",attrs:{"to":{
                      name: 'logistic-output-show',
                      params: { output_id: l.id }
                    },"tag":"a","title":"Editar/ver"}},[_c('i',{staticClass:"fa fa-edit"})])],1)])])})],2)])],2)],1)]),_c('app-modal-basic',{ref:"dFormCreat",attrs:{"xl":true}},[_c('Form',{ref:"formCre",on:{"submitted":function($event){return _vm.$router.push({
          name: 'logistic-output-show',
          params: { output_id: $event }
        })}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }